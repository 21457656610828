<script setup>
import { ref, watch, onMounted, defineProps, nextTick, defineExpose } from 'vue';
import { useChat } from "@/components/Chat/useChat";
import { v4 } from "uuid";
import { useSessionStore } from '@/store';
import hljs from "highlight.js";
import { chatResultFormater, encodeHtml, generateHTMLTable } from "@/utils/formater";
import { ElInput, } from "element-plus";
import { likeMessage, getSessionHistoryById, putSessionFeedback } from "@/api/api";

const store = useSessionStore()
// 定义一个响应式数据，用于绑定输入框的值
const inputValue = ref('');
const isShowConversation = ref(true);
import { countTokens } from 'gpt-tokenizer'
// 定义消息数据
const messages = ref([]);

// 定义一个 ref 来存储 setTimeout 返回的 ID
const timeoutId = ref(null);
const timeoutRestartId = ref(null);
const isShowRestartBtn = ref(false);
const isMaxToken = ref(false)
const isMobile = ref(true)
const isComposing = ref(false) // 标记是否处于输入法组合状态
const isTransfer = ref(false)
const feedbackSubmitted = ref(false)
const isNeedMockHumanAgent = ref(false)

// 定义 props，这里 moresProblem 是一个数组
defineProps({
  moresProblem: {
    type: Array,
    required: true // 可根据需要决定是否是必传项
  },
  botId: {
    type: [Number, String],
    default: ''
  },
  isAdmin: {
    type: Boolean,
    default: false
  }
});


// const voteStatus = ref(0) // 0: 未投票, 1: helpful, 2: no help
// 添加一个对象来存储每条消息的点赞状态，以消息ID为key
const messageVotes = ref({})



const emit = defineEmits(['triggerShow']);
const inputRef = ref(null);
const inputRef2 = ref(null);

const isShowYellowSend = ref(false)
const isEndSession = ref(false)


const checkScreenSize = () => {
  const mediaQuery = window.matchMedia('(max-width: 768px)');
  isMobile.value = mediaQuery.matches;

  // 监听变化
  mediaQuery.addEventListener('change', (e) => {
    isMobile.value = e.matches;
  });
};



onMounted(async () => {
  checkScreenSize(); // 初次加载时检查
  window.addEventListener('resize', checkScreenSize); // 监听后续变化
  //如果不是手机需要focus
  if (!isMobile.value) {
    console.log('我focus了', inputRef.value)
    nextTick(() => {
      inputRef.value?.focus();  // 页面加载后给input元素加焦点
    })
  }
})


// 处理输入事件
const handleInput = (value) => {
  // 如果不在输入法组合状态，实时更新
  if (!isComposing.value) {
    isShowYellowSend.value = value.trim().length > 0
  }
}

// 处理输入法开始组合
const handleCompositionStart = () => {
  isComposing.value = true
  // 中文输入法开始时立即显示黄色按钮
  isShowYellowSend.value = true

}

// 处理输入法结束组合
const handleCompositionEnd = () => {
  isComposing.value = false
  // 输入法结束时检查最终内容
  isShowYellowSend.value = inputValue.value.trim().length > 0
}
// 根据 showAllSources 的状态来决定每条消息显示的 sources
const visibleSources = (message) => {
  if (message.showAllSources) {
    return message.sources;
  } else {
    return message.sources.slice(0, 3); // 默认只显示第一个 source
  }
}

// 切换每条消息的 showAllSources 状态
const toggleShowAllSources = (message) => {
  message.showAllSources = !message.showAllSources;
}


// 在其他地方清除定时器
const clearMyTimeout = () => {
  console.log('准备清楚')
  if (timeoutId.value !== null) {
    console.log('清除开始')
    clearTimeout(timeoutId.value);
    timeoutId.value = null;  // 清空 timeoutId，确保不会重复清除
  }

  if (timeoutRestartId.value !== null) {
    clearTimeout(timeoutRestartId.value);
    timeoutRestartId.value = null;  // 清空 timeoutId，确保不会重复清除
  }
}


const countToken = () => {

  let tokenText = ''
  messages.value.forEach((item) => {
    tokenText += item.content
  })

  const text = inputValue.value.trim();

  tokenText += text;

  let tokenNumber = countTokens(tokenText)
  if (tokenNumber > 3500 && !isMaxToken.value) {
    isMaxToken.value = true
  }

}

const clickSendMsg = async () => {
  const text = inputValue.value.trim();
  isShowYellowSend.value = false;
  isComposing.value = false
  if (loading.value || !text) {
    return
  }


  
  //零食text
  let tempText = text.toLowerCase()
  if(tempText.includes('connect to live chat')){
    isNeedMockHumanAgent.value = true
  }


  countToken();

  clearMyTimeout()
  isShowConversation.value = false;

  inputValue.value = ''
  await sendRecommendQuestion({ value: text })

}

const clickMoreProblem = async (item) => {
  isShowConversation.value = false;
  await sendRecommendQuestion({ value: item })
}


const dialogVisible = ref(false)
const mobileDialogVisible = ref(false)
const handleEndSession = async () => {
  dialogVisible.value = true
}

const handleEndSessionMobile = () => {
  mobileDialogVisible.value = true
}

const confirmEndSession = async () => {
  dialogVisible.value = false
  mobileDialogVisible.value = false
  isEndSession.value = true;

  clearMyTimeout()
  if (store.loading) {
    stopChat()
  }

  insertInquiry('endSession')
  //调用api
  // await putSessionFeedback(store.sessionId, {
  //   comments: '',
  //   vote_status: 5
  // })

  // 使用 nextTick 确保在 DOM 更新后再滚动
  await scrollBottom();

}


const scrollBottom = async () => {
  await nextTick(() => {
    if (historyContainerRef.value) {
      historyContainerRef.value.scrollTop = historyContainerRef.value.scrollHeight
    }
  })
}

const parseCodeText2Html = (input) => {
  const sections = extractCodeAndNonCodeSections(input)
  for (const section of sections) {
    let val = ""
    if (section.type === 'code') {
      if (section.language !== "plaintext") {
        try {
          val = hljs.highlight(section.text, {
            language: section.language == 'vue' ? 'html' : section.language,
            ignoreIllegals: true
          }).value
        } catch (e) {
          val = hljs.highlight(section.text, { language: "plaintext", ignoreIllegals: true }).value
        }
      } else {
        const { secondBest, value } = hljs.highlightAuto(section.text)
        if (secondBest) {
          const { value, language } = secondBest
          if (language) {
            section.language = language
          }
          val = value
        } else {
          val = value
        }

      }
      if (section.language) {
        section.class = `hljs language-${section.language}`
      }
    } else if (section.type == 'txt') {
      val = chatResultFormater(section.text)
    } else if (section.type == 'table') {
      val = generateHTMLTable(section.column, section.rows)
    }
    section.html = val
  }

  return sections
}

function extractCodeAndNonCodeSections(text) {
  const lines = text.split("\n")
  const languagePattern = /^```(\w*)$/
  const tableRowPattern = /^\|?(.+\|)+(.+)?$/
  const tableSpliterPattern = /(^\|?(\s*-+\s*\|)+(\s*-+)?$)|(^\|?((\s*-\s*)+\|)+(\s*-+)?$)/
  let section = []
  let language = ''
  let codeStart = false
  let column = []
  let tableHeader = ''
  let rows = []
  const sections = [];
  for (const line of lines) {
    const temp = line.trim()
    const match = languagePattern.exec(temp)
    const tableMatch = !codeStart && tableRowPattern.test(temp)
    if (match) {
      if (column.length) {
        sections.push({ type: "table", language: language, column: [...column], rows: [...rows] })
        column = []
        rows = []
      }
      if (codeStart) {
        sections.push({ type: "code", language: language, text: section.join("\n") })
        section = []
        codeStart = false
      } else {
        language = match[1]
        if (!language) {
          language = "plaintext"
        }
        if (section.length) {
          sections.push({ type: "txt", language: null, text: section.join("\n") })
          section = []
        }
        codeStart = true
      }

    } else if (tableMatch) {
      if (column.length > 0) {
        const row = chatResultFormater(temp).split("|").map(i => i.trim())
        if (!row[0]) {
          row.shift()
        }
        if (!row[row.length - 1]) {
          row.pop()
        }
        while (row.length < column.length) {
          row.push('')
        }
        rows.push(row.slice(0, column.length))
      } else if (tableHeader) {
        if (tableSpliterPattern.test(temp)) {
          const row = temp.split("|").map(i => i.trim())
          if (!row[0]) {
            row.shift()
          }
          if (!row[row.length - 1]) {
            row.pop()
          }
          const header = chatResultFormater(tableHeader).split("|").map(i => i.trim())
          if (!header[0]) {
            header.shift()
          }
          if (!header[header.length - 1]) {
            header.pop()
          }
          if (row.length === header.length) {
            tableHeader = ''
            if (section.length) {
              if (codeStart) {
                sections.push({ type: "code", language: language, text: section.join("\n") })
              } else {
                sections.push({ type: "txt", language: null, text: section.join("\n") })
              }
              section = []
            }
            column = header
          } else {
            section.push(tableHeader)
            section.push(line)
          }
        } else {
          section.push(tableHeader)
          tableHeader = temp
        }

      } else {
        tableHeader = temp
      }
    } else {
      if (column.length) {
        sections.push({ type: "table", language: language, column: [...column], rows: [...rows] })
        column = []
        rows = []
      }
      section.push(line)
    }
  }
  if (column.length) {
    sections.push({ type: "table", language: language, column: [...column], rows: [...rows] })
    tableHeader = ''
  }
  if (tableHeader) {
    section.push(tableHeader)
  }
  if (section.length) {
    if (codeStart) {
      sections.push({ type: "code", language: language, text: section.join("\n") })
    } else {
      sections.push({ type: "txt", language: null, text: section.join("\n") })
    }
  }
  sections.forEach(e => {
    if (e.type === 'text') {
      e.text = encodeHtml(e.text)
    }
  })
  return sections
}


const info = ref({ id: localStorage.getItem('botId') })
const sessionId = v4()
store.updateSessionId(sessionId)


const {
  loading,
  chatData,
  historyContainerRef,
  messagePairs,
  sendRecommendQuestion,
  stopChat
} = useChat(info, sessionId)


const clickStopChat = () => {
  stopChat()
}

//这边我是不是可以写成 watch isShowConversation  然后传递给父级
watch(isShowConversation, (newVal) => {
  emit('update:isShowConversation', newVal)
})

// 监听 sessionStore.loading 的变化
// 监听 loading 的变化
watch(
  () => store.loading,
  (newVal) => {

    // 当 loading 为 false 时，开始计时
    if (newVal === false) {


      // 如果之前有定时器在运行，先清除
      if (timeoutId.value !== null) {
        clearTimeout(timeoutId.value);
      }

      // 如果之前有定时器在运行，先清除
      if (timeoutRestartId.value !== null) {
        clearTimeout(timeoutRestartId.value);
      }

      if (!isEndSession.value) {
        timeoutRestartId.value = setTimeout(() => {
          isShowRestartBtn.value = true
          // 10秒后执行这个函数
          insertInquiry('end')
          scrollBottom()

          isEndSession.value = true;
          // confirmEndSession();

        }, 300000); // 5分钟 (300000)

        timeoutId.value = setTimeout(() => {
          // 10秒后执行这个函数
          insertInquiry('reminder');
          scrollBottom()
        }, 180000); // 3分钟 (180000)
      }



      countToken();
      // inputRef2.value?.focus();  // 页面加载后给input元素加焦点
      //如果超出了
      if (isMaxToken.value) {
        if (timeoutId.value !== null) {
          clearTimeout(timeoutId.value);
        }
        // 如果之前有定时器在运行，先清除
        if (timeoutRestartId.value !== null) {
          clearTimeout(timeoutRestartId.value);
        }
        isShowRestartBtn.value = true
        insertInquiry('maxToken')
      }


      isNeedMockHumanAgent.value = false

      scrollBottom()

    }
  }
);

// 新增消息单独投票的处理函数
const handleMessageVote = async (message, status) => {
  const newStatus = message.voteStatus === status ? 0 : status;
  message.voteStatus = newStatus;
  messageVotes.value[message.id] = newStatus;

  const action = newStatus === 0 ? 'no_behavior' :
    newStatus === 1 ? 'like' : 'dislike';
  const payload = {
    session_id: store.sessionId,
    page_size: 1000,
  }

  //换成await
  const res = await getSessionHistoryById(payload)
  //根据message 找到在messages是第几个  然后去res.list的第几个 取到id  作为 message.id 入参
  const index = messages.value.findIndex((item) => item.id === message.id)
  // 如果没找到
  if (index === -1) {
    return
  }
  const historyItem = res.list[index]
  const historyId = historyItem.id
  await likeMessage(historyId, { action })

  // Add call for previous message if it exists
  if (index > 0) {
    const previousHistoryItem = res.list[index - 1]
    const previousHistoryId = previousHistoryItem.id
    await likeMessage(previousHistoryId, { action })
  }

}

// 在 watch messagePairs 时为每条消息添加 voteStatus 字段
watch(
  () => messagePairs.value,
  (newValue) => {
    let newList = [];

    console.log('newValue', newValue)
    newValue.forEach((item) => {
      if (item.user) {
        newList.push({
          type: 'user-message',
          ...item.user,
        });
      }
      const contentText = parseCodeText2Html(item.assistant.content);
      if (item.assistant && item.assistant.sources) {
        // 先用 Set 去重
        let newCitsMap = new Set();
        item.assistant.sentences.forEach((sentenItem) => {
          if (sentenItem.cites && sentenItem.cites.length > 0) {
            sentenItem.cites.forEach((citesItem) => {
              newCitsMap.add(citesItem.order); // 用 add 而不是 push
            });
          }
        });
        // 转回数组
        newCitsMap = Array.from(newCitsMap);
        // 过滤 sources
        item.assistant.sources = item.assistant.sources.filter((source) => {
          return newCitsMap.includes(source.order);
        });

        // 根据 sentences 中 cites 的出现顺序重新排列 sources
        const citesOrder = [];
        item.assistant.sentences.forEach((sentenItem) => {
          if (sentenItem.cites && sentenItem.cites.length > 0) {
            sentenItem.cites.forEach((citesItem) => {
              // 只添加未重复的 id，保证按照首次出现顺序
              if (!citesOrder.some((item) => item.id === citesItem.id)) {
                citesOrder.push(citesItem);
              }
            });
          }
        });

        // 重新排序 sources
        item.assistant.sources = citesOrder.map((cite) =>
          item.assistant.sources.find((source) => source.id === cite.id)
        ).filter(Boolean); // 过滤掉 undefined
      }

      console.log('item', item)
      // 判断contentText[0].html 是否包含 $[TRANSFER]  如果有 就替换为空
      newList.push({
        type: 'ai-message',
        isShowFoundSource: false,
        // 从存储对象中获取投票状态，如果不存在则为0
        voteStatus: messageVotes.value[item.assistant.id] || 0,
        showVoteActions: false, // Add property to control vote actions visibility
        ...item.assistant,
        content: contentText[0].html,
      });
    });

    console.log('newList', newList)

    //newlist可能为空数组
    if (newList.length === 0) {
      return
    }
    //对最后的newlist 进行判断 如果最后一个的content 包含 $[TRANSFER]  就打印一下日志   并且这只手在替换为空  必须type为ai-message 必须9:00~18:00
    const now = new Date()
    const hours = now.getHours()
    if (hours >= 9 && hours < 18) {


      if (newList[newList.length - 1].type === 'ai-message' && newList[newList.length - 1].content.includes('$[TRANSFER]')) {
        newList[newList.length - 1].content = newList[newList.length - 1].content.replace('$[TRANSFER]', '')
        newList[newList.length - 1].isTransfer = true
      }


      if(newList[newList.length - 1].type === 'ai-message' && isNeedMockHumanAgent.value){
        newList[newList.length - 1].isTransfer = true
      }
      
    }
    // 如果其他的也有 $[TRANSFER]  就替换
    newList.forEach((item) => {
      if (item.type === 'ai-message') {
        // 使用正则表达式进行全局替换，不区分大小写
        item.content = item.content.replace(/\$?\[TRANSFER\]/gi, '');

        // Replace other patterns
        item.content = item.content
          .replace(/\$?\[Tips\s*\d+\]/g, '')
          .replace(/\[Information\s*\d+\]/g, '');
      }
    })


    messages.value = newList;
  },
  { deep: true }
);


const handleKeydown = (event) => {
  if (!event.ctrlKey && event.key === "Enter") {
    event.preventDefault()
    clickSendMsg();
    inputValue.value = ""
  } else if (event.ctrlKey && event.key === "Enter") {
    inputValue.value += "\n"
    clickSendMsg();
  }
}


const restart = () => {
  if (store.loading) {
    stopChat()
  }
  clearMyTimeout()
  emit("triggerShow")
  window.embeddedservice_bootstrap.utilAPI.removeAllComponents()
}


defineExpose({
  restart
})

// 将__COLON__、__SLASH__和__DOT__替换为对应符号
const transformSourceName = (sourceName) => {
  let result = sourceName
    .replace(/__COLON__/g, ':')
    .replace(/__SLASH__/g, '/')
    .replace(/__DOT__/g, '.');
  const txtRegex = /\.txt$/;
  if (txtRegex.test(result)) {
    result = result.replace(txtRegex, '');
  }
  return result;
};

// 打开链接的点击事件
const openLink = (sourceName) => {
  const transformedUrl = transformSourceName(sourceName);
  window.open(transformedUrl, '_blank');
}


const insertInquiry = (messageType) => {
  let content = '';

  if (messageType === 'end') {
    content = "Hi there, It's been a while since your last message. I'm going to leave this chat for now, but feel free to reach out to us again.\n";
  } else if (messageType === 'reminder') {
    content = "Hello! I'm still here and ready to assist with any questions or concerns you may have about Sentosa. Please feel free to continue our conversation or let me know if you need any further information.";
  } else if (messageType === 'maxToken') {
    content = "I apologise, but our conversation has gotten too long. To help me stay sharp and give you the best help possible,it's goodwe start a fresh chat. Just copy over any important bits from here, and we can pick up right where we left off. Thanks for bearing with me. ";
  } else if (messageType === 'endSession') {
    content = '~~@@endSession@@~~'
  }

  chatData.value.push({
    "id": v4(),
    "role": "assistant",
    "loading": false,
    "generating": false,
    "status": "success",
    "content": content
  });
}

// Add this function to determine if a message is the last AI message
const isLastAiMessage = (index) => {
  // Simply check if this is the last message in the array
  return index === messages.value.length - 1;
};

// Add this function to handle mouse hover events
const handleMessageHover = (message, isHovering) => {
  if (message.type === 'ai-message' && message.status === 'success') {
    message.showVoteActions = isHovering;
  }
};


// const scriptLoaded = ref(false);


const loadEmbeddedMessaging = async () => {
  //额外打开一个窗口https://direct.lc.chat/6374851/



  dialogVisible.value = false
  mobileDialogVisible.value = false
  isEndSession.value = true;
  isTransfer.value = true
  clearMyTimeout()
  window.open('https://direct.lc.chat/6374851/', '_blank')

  return;
  //
  // if (scriptLoaded.value) {
  //   console.log('Script already loaded.');
  //   setHiddenPrechatFieldsFn();
  //   //如果已经加载 就直接启动
  //   window.embeddedservice_bootstrap.utilAPI.launchChat()
  //
  //
  //   return;
  // }
  //
  // const script = document.createElement('script');
  // script.id = 'embedded-messaging-script';
  // script.type = 'text/javascript';
  //
  // //新的
  // if (localStorage.getItem('botId') === '4') {
  //   script.src = 'https://sentosadevelopmentcorporation.my.site.com/ESWWizAILiveChat1742309706321/assets/js/bootstrap.min.js'
  // }
  //
  // if (localStorage.getItem('botId') === '37') {
  //   script.src = 'https://sentosadevelopmentcorporation--sdcuat.sandbox.my.site.com/ESWWizAITest1740121991593/assets/js/bootstrap.min.js';
  //
  // }
  // //老的
  // script.onload = initEmbeddedMessaging;
  // document.body.appendChild(script);
  // scriptLoaded.value = true;
};




// const setHiddenPrechatFieldsFn = (retryCount = 0, maxRetries = 10) => {
//   // 获取最后8条用户消息
//   const lastUserMessages = messages.value
//     .filter(msg => msg.type === 'user-message')
//     .slice(-8)  // 只取最后8条
//     .map(msg => msg.content);
//
//   // 构建要发送的字段对象
//   const prechatFields = {};
//
//   // 只为有实际消息的字段创建键值对
//   lastUserMessages.forEach((msg, index) => {
//     prechatFields[`Question${index + 1}`] = msg;
//   });
//   console.log('prechatFields', prechatFields)
//
//   try {
//     // 设置实际有的消息字段
//     window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(prechatFields);
//     console.log('设置隐藏字段成功');
//   } catch (error) {
//     console.error('设置隐藏字段失败:', error);
//
//     // 如果还有重试次数，则延迟后重试
//     if (retryCount < maxRetries) {
//       console.log(`重试 (${retryCount + 1}/${maxRetries})...`);
//       setTimeout(() => {
//         setHiddenPrechatFieldsFn(retryCount + 1, maxRetries);
//       }, 1000); // 1秒后重试
//     }
//   }
// }
//
// const initEmbeddedMessaging = () => {
//   try {
//     window.embeddedservice_bootstrap.settings.language = 'en_US';
//     window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;
//
//     //新的
//     if (localStorage.getItem('botId') === '4') {
//       window.embeddedservice_bootstrap.init(
//         '00DBe0000008csD',
//         'WizAI_LiveChat',
//         'https://sentosadevelopmentcorporation.my.site.com/ESWWizAILiveChat1742309706321',
//         {
//           scrt2URL: 'https://sentosadevelopmentcorporation.my.salesforce-scrt.com'
//         }
//       );
//     }
//
//
//
//     //老的
//     if (localStorage.getItem('botId') === '37') {
//       window.embeddedservice_bootstrap.init(
//         '00DEW0000002K5t',
//         'WizAI_Test',
//         'https://sentosadevelopmentcorporation--sdcuat.sandbox.my.site.com/ESWWizAITest1740121991593',
//         {
//           scrt2URL: 'https://sentosadevelopmentcorporation--sdcuat.sandbox.my.salesforce-scrt.com'
//         }
//       );
//     }
//
//
//
//     setTimeout(setHiddenPrechatFieldsFn, 1000);
//
//     console.log("onEmbeddedMessagingReady event triggered");
//
//     // Add a check for the launchChat method with retry logic
//     const maxRetries = 10;
//     let retryCount = 0;
//
//     const tryLaunchChat = () => {
//       if (window.embeddedservice_bootstrap.utilAPI &&
//         typeof window.embeddedservice_bootstrap.utilAPI.launchChat === 'function') {
//
//         window.embeddedservice_bootstrap.utilAPI.launchChat()
//           .then(() => {
//             console.log('Chat launched successfully');
//           })
//           .catch((error) => {
//             console.error('Error launching chat:', error);
//           })
//           .finally(() => {
//             console.log('Chat launch attempt completed');
//           });
//
//       } else {
//         retryCount++;
//         if (retryCount <= maxRetries) {
//           console.log(`launchChat method not available yet. Retry attempt ${retryCount}/${maxRetries}`);
//           setTimeout(tryLaunchChat, 1000); // Retry after 1 second
//         } else {
//           console.error('Failed to find launchChat method after maximum retries');
//         }
//       }
//     };
//
//     // Start the first attempt after a short delay to allow initialization
//     setTimeout(tryLaunchChat, 1000);
//
//   } catch (err) {
//     console.error('Error loading Embedded Messaging: ', err);
//   }
// };

// Add new ref for star rating
const starRating = ref(0); // Default to 3 stars
const feedback = ref(''); // Add feedback ref

// Add function to handle star click
const handleStarClick = (rating) => {
  starRating.value = rating;

}




const handleFeedbackSubmit = () => {
  // Add your feedback submission logic here
  if (starRating.value === 0) {
    return
  }
  confirmEndSession();
  feedbackSubmitted.value = true
  putSessionFeedback(store.sessionId, {
    comments: feedback.value,
    vote_status: starRating.value
  })
}
</script>

<template>
  <div v-if="isMobile">
    <div class="sentosa-buddy-container" v-if="isShowConversation">
      <div class="header">
        <img src="@/assets/img/sentosa/sentosa_logo.svg" class="assistant-logo" />
      </div>
      <div class="scrollable-content">
        <div class="logo-row">
          <img src="@/assets/img/sentosa/sentosa_small_logo.svg" class="assistant-logo" />
        </div>
        <!--        <p class="greeting-title">How may I help you today?</p>-->
        <div class="assistant-intro">
<!--          <img src="@/assets/img/sentosa/paintingBrush.svg" class="assistant-icon" />-->
          <div class="assistant-text">

            <div>
              Hello! I'm <span>Summer AI</span>, your Sentosa digital guide.
            </div>
            <div>How may I help you today?</div>

          </div>
        </div>


        <div class="options">
          <div class="option" v-for="(item, index) in moresProblem" :key="index" @click="clickMoreProblem(item)">
            {{ item }}
          </div>
        </div>
      </div>

      <div class="fixed-bottom">
        <div class="input-wrapper">
          <el-input type="textarea" ref="inputRef" :autosize="{ minRows: 1, maxRows: 5 }" :disabled="loading"
            resize="none" @keydown="handleKeydown" @input="handleInput" @compositionstart="handleCompositionStart"
            @compositionend="handleCompositionEnd" v-model="inputValue" placeholder="Please enter..." />
          <img src="@/assets/img/sentosa/send_yellow.svg" v-if="isShowYellowSend" class="send-logo"
            @click="clickSendMsg(true)" />
          <img src="@/assets/img/sentosa/send.svg" v-else class="send-logo" @click="clickSendMsg(true)" />

        </div>
        <div class="disclaimer">
          I'm an AI assistant and still learning, so please verify any crucial information.For details on how we protect
          your data, please refer to our <a href="https://www.sentosa.com.sg/en/data-protection-policy/" target="_blank"
            class="policy-link">
            Data Protection Policy
          </a>.

        </div>
      </div>
    </div>
    <div class="sentosa-dialog-container" v-else>
      <!-- 固定头部 -->
      <div class="header">
        <div style="display: flex">
          <img src="@/assets/img/sentosa/sentosa_logo.svg" class="assistant-logo" />
        </div>
        <div class="newChat_view" @click="restart" v-if="!isTransfer">
          <img src="@/assets/img/sentosa/newChat.svg" class="newChat_icon" />
          <div class="title">
            New topic?
            Start fresh here
          </div>
          <!--          <span class="title">New Chat</span>-->
        </div>
      </div>

      <!-- 中间对话模块 -->
      <div class="dialog-content" ref="historyContainerRef">

        <div v-for="(message, index) in messages" :key="index">
          <div :class="['message', message.type === 'ai-message' ? 'bot-message' : 'user-message']">

            <div v-if="message.loading" class="message_loading">
              <img src="@/assets/img/sentosa/message_loading.svg" class="loading_logo" />
              <div class="title">Thinking...</div>
            </div>

            <template v-else>



              <template v-if="message.content === '~~@@endSession@@~~'">
<!--                <div class="voteView_mobile" v-if="!feedbackSubmitted">-->
<!--                  <div>-->
<!--                    <div class="content_view">-->
<!--                      Thank you for chatting with us.-->
<!--                    </div>-->
<!--                    <div class="desc_view">-->
<!--                      How was your experience?-->
<!--                    </div>-->
<!--                    <div class="start_view">-->
<!--                      <div class="stars">-->
<!--                        <template v-for="index in 5" :key="index">-->
<!--                          <img-->
<!--                            :src="require(`@/assets/img/sentosa/${index <= starRating ? 'star_yellow' : 'no_star'}.svg`)"-->
<!--                            class="start_icon" @click="handleStarClick(index)" />-->
<!--                        </template>-->
<!--                      </div>-->
<!--                      <div class="start_number_view">-->
<!--                        <span class="left_number">{{ starRating }}</span>-->
<!--                        <span class="right_number">/5 stars</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="feedback_view">-->
<!--                    <div class="feedback_desc">Share your feedback (Optional)</div>-->
<!--                    <el-input v-model="feedback" type="textarea" :maxlength="500" :show-word-limit="true"-->
<!--                      placeholder="What can we do to improve?" rows="5" />-->
<!--                    <div class="feedback_button_view">-->
<!--                      <div :class="['submit_button', starRating === 0 ? 'no_select_btn' : '']"-->
<!--                        @click="handleFeedbackSubmit">Send Your-->
<!--                        Feedback</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <template  v-if="feedbackSubmitted" >
                  <div class="feedback-success">
                    <img src="@/assets/img/sentosa/feedBack_success.svg" class="success-icon" />
                    <span class="success-text">Thank you for your feedback!</span>
                  </div>
                </template>




              </template>







              <template v-else>
                <div v-if="message.type === 'ai-message'" v-html="message.content"
                  style=" font-family: Montserrat;line-height: 26px">
                </div>
                <div v-else>{{ message.content }}</div>
              </template>

            </template>
          </div>


          <div class="vote-actions"
            v-show="message.type === 'ai-message' && message.status === 'success' && message.content !== '~~@@endSession@@~~'">
            <div class="vote-button" @click="handleMessageVote(message, 1)">
              <img :src="require(`@/assets/img/sentosa/${message.voteStatus === 1 ? 'helpful_active' : 'helpful'}.svg`)"
                class="vote-icon" />
            </div>
            <div class="vote-button" @click="handleMessageVote(message, 2)">
              <img :src="require(`@/assets/img/sentosa/${message.voteStatus === 2 ? 'no_help_active' : 'no_help'}.svg`)"
                class="vote-icon" />
            </div>
          </div>

          <div class="suggestions" v-if="message.status === 'success' && message.sources && !message.isShowFoundSource">
            <div class="suggestion" v-for="(basedSourceItem) in visibleSources(message)" :key="basedSourceItem.id"
              @click="openLink(basedSourceItem.source_data)">
              {{ transformSourceName(basedSourceItem.source_data) }} <img src="@/assets/img/sentosa/rightIcon.svg"
                class="rightIcon" />
            </div>
          </div>
          <!-- Desktop transfer button -->
          <div class="transfer_agent_btn_mobile" @click="loadEmbeddedMessaging"
            v-if="message.status === 'success' && message.type === 'ai-message' && message.isTransfer && !isTransfer">
            <img src="@/assets/img/sentosa/transferUser_icon.svg" class="transfer_icon" />
            Transfer to live agent
          </div>
          <!--          <div class="text-with-lines" v-if="!isTransfer &&  isEndSession && index === messages.length -1">-->
          <!--            Chat rated successfully-->
          <!--          </div>-->
        </div>


      </div>

      <div class="other_operation">
        <div class="new-chat" @click="handleEndSessionMobile" v-if="isShowRestartBtn &&  !feedbackSubmitted ">
          <img src="@/assets/img/sentosa/start_new_chat_icon.svg" class="icon" />
          Rate Session (Your views matter!)
        </div>

      </div>


        <div class="end_session_view_mobile" @click="handleEndSessionMobile" v-if="!isEndSession">
          <img src="@/assets/img/sentosa/start_new_chat_icon.svg" class="icon" />

          Rate & End Session (Your views matter!)
      </div>

      <!-- 复用底部固定输入区域 -->
      <div class="fixed-bottom">
        <div class="input-wrapper">
          <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 5 }" resize="none" ref="inputRef2"
            v-model="inputValue" @keydown="handleKeydown" @input="handleInput"
            @compositionstart="handleCompositionStart" @compositionend="handleCompositionEnd"
            placeholder="Please enter..." :disabled="loading || isShowRestartBtn || isEndSession" />

          <template v-if="store.loading">
            <el-tooltip class="box-item" effect="dark" content="Stop generation" placement="top">
              <img src="@/assets/img/sentosa/stop.svg" class="send-logo" @click="clickStopChat" />
            </el-tooltip>
          </template>
          <template v-else>
            <img src="@/assets/img/sentosa/send_yellow.svg" v-if="isShowYellowSend" class="send-logo"
              @click="clickSendMsg(true)" />
            <img src="@/assets/img/sentosa/send.svg" v-else class="send-logo" @click="clickSendMsg(true)" />
          </template>

        </div>
        <div class="disclaimer">
          I'm an AI assistant and still learning, so please verify any crucial information.
          For details on how we protect your data, please refer to our <a
            href="https://www.sentosa.com.sg/en/data-protection-policy/" target="_blank" class="policy-link">
            Data Protection Policy
          </a>.
        </div>
      </div>
    </div>
  </div>
  <div class="sentosa-assistant-container" v-else>

    <div class="header" v-if="!isAdmin && !isMobile">
      <div style="display: flex">
        <img src="@/assets/img/sentosa/sentosa_logo.svg" class="assistant-logo" />
      </div>
      <div class="new_chat_view" v-if="!isShowConversation && !isTransfer" @click="restart">
        <el-tooltip class="box-item" effect="dark" content="Start a new chat" placement="top-start">
          <div class="new_chat_item_body">
            <img src="@/assets/img/sentosa/newChat.svg" class="new_chat_logo" />
            <div class="title">New topic?
              Start fresh here</div>
          </div>
        </el-tooltip>
      </div>

    </div>
    <div class="assistant-content" v-if="isShowConversation">
      <!--      <div class="assistant-title">-->
      <!--        How may I help you today?-->
      <!--      </div>-->

      <div class="input-container">
        <div class="assistant-intro">
          <img src="@/assets/img/sentosa/sentosa_small_logo.svg" />

          <div class="assistant-intro_text">
            <div>
<!--              Hello! I'm your <span> Sentosa AI</span> Guide-->
              Hello! I'm <span>Summer AI</span>, your Sentosa digital guide.
            </div>
            <div>
              How may I help you today?
            </div>
          </div>
        </div>
        <div class="input-wrapper">
          <el-input type="textarea" @input="handleInput" @compositionstart="handleCompositionStart"
            @compositionend="handleCompositionEnd" ref="inputRef" :autosize="{ minRows: 1, maxRows: 5 }"
            :disabled="loading" resize="none" @keydown="handleKeydown" v-model="inputValue"
            placeholder="Please enter..." />

          <img src="@/assets/img/sentosa/send_yellow.svg" v-if="isShowYellowSend" class="send-logo"
            @click="clickSendMsg(true)" />
          <img src="@/assets/img/sentosa/send.svg" v-else class="send-logo" @click="clickSendMsg(true)" />
        </div>
      </div>
      <div class="more-select-container">
        <div class="more_item" v-for="(item, index) in moresProblem" :key="index" @click="clickMoreProblem(item)">
          <div class="title_view">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="footer">
        I'm an AI assistant and still learning, so please verify any crucial information.
        For details on how we protect your data, please refer to our <a
          href="https://www.sentosa.com.sg/en/data-protection-policy/" target="_blank" class="policy-link">
          Data Protection Policy
        </a>.

      </div>

    </div>

    <div class="conversation-content" v-else>
      <div class="conversation-view">
        <!-- 聊天消息列表 -->
        <div class="messages-container" ref="historyContainerRef">
          <div v-for="(message, index) in messages" :key="index" :class="['message', message.type]"
            @mouseenter="handleMessageHover(message, true)" @mouseleave="handleMessageHover(message, false)">
            <div class="message-content">
              <img v-if="message.type === 'ai-message'" src="@/assets/img/sentosa/avatar.svg" class="assistant-logo" />
              <div class="body-view">


<!--                <div class="message_loading">-->
<!--                  <img src="@/assets/img/sentosa/message_loading.svg" class="loading_logo" />-->
<!--                  <div class="message-title">Thinking...</div>-->
<!--                </div>-->

                <div v-if="message.loading" class="message_loading">
                  <img src="@/assets/img/sentosa/message_loading.svg" class="loading_logo" />
                  <div class="message-title">Thinking...</div>
                </div>

                <template v-else>
                  <template v-if="message.content === '~~@@endSession@@~~'">
                    <div class="feedback-success" v-if="feedbackSubmitted">
                      <img src="@/assets/img/sentosa/feedBack_success.svg" class="success-icon" />
                      <span class="success-text">Thank you for your feedback!</span>
                    </div>

                    <!--                    <div v-else>-->
                    <!--                      <div class="voteView">-->
                    <!--                        <div class="content_view">-->
                    <!--                          Thank you for chatting with us.11-->
                    <!--                        </div>-->
                    <!--                        <div class="desc_view">-->
                    <!--                          How was your experience?-->
                    <!--                        </div>-->
                    <!--                        <div class="operation_view">-->
                    <!--                          <div class="start_view">-->
                    <!--                            <div>-->
                    <!--                              <template v-for="index in 5" :key="index">-->
                    <!--                                <img-->
                    <!--                                  :src="require(`@/assets/img/sentosa/${index <= starRating ? 'star_yellow' : 'no_star'}.svg`)"-->
                    <!--                                  class="start_icon" @click="handleStarClick(index)" style="cursor: pointer;" />-->
                    <!--                              </template>-->
                    <!--                            </div>-->

                    <!--                            <div class="start_number_view">-->
                    <!--                              <span class="left_number">{{ starRating }}</span>-->
                    <!--                              <span class="right_number">/5 stars</span>-->
                    <!--                            </div>-->
                    <!--                          </div>-->


                    <!--                        </div>-->
                    <!--                        <div class="feedback_pc_view">-->
                    <!--                          <div class="feedback_desc">-->
                    <!--                            Share your feedback (Optional)-->
                    <!--                          </div>-->
                    <!--                          <el-input v-model="feedback" type="textarea" :maxlength="500" :show-word-limit="true"-->
                    <!--                            placeholder="What can we do to improve?" rows="5" />-->
                    <!--                          <div class="feedback_button_view">-->
                    <!--                            <div :class="['submit_button', starRating === 0 ? 'no_select_btn' : '']"-->
                    <!--                              @click="handleFeedbackSubmit">Send-->
                    <!--                              Your Feedback</div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                  </template>
                  <template v-else>
                    <div v-if="message.type === 'ai-message'" v-html="message.content"
                      style=" font-family: Montserrat;line-height: 26px">
                    </div>
                    <div v-else class="content">{{ message.content }}</div>
                  </template>
                </template>
              </div>
            </div>

            <!-- 这边加2个div 是点赞 和点 -->
            <div class="vote-actions" v-show="message.type === 'ai-message' && message.status === 'success' && message.content !== '~~@@endSession@@~~' &&
              (isLastAiMessage(index) || message.showVoteActions)"
              :class="{ 'vote-actions-visible': isLastAiMessage(index) || message.showVoteActions }">
              <div class="vote-button" @click="handleMessageVote(message, 1)">
                <img
                  :src="require(`@/assets/img/sentosa/${message.voteStatus === 1 ? 'helpful_active' : 'helpful'}.svg`)"
                  class="vote-icon" />
              </div>
              <div class="vote-button" @click="handleMessageVote(message, 2)">
                <img
                  :src="require(`@/assets/img/sentosa/${message.voteStatus === 2 ? 'no_help_active' : 'no_help'}.svg`)"
                  class="vote-icon" />
              </div>
            </div>

            <div class="based_source-view"
              v-if="message.status === 'success' && message.sources && !message.isShowFoundSource">
              <!-- 显示部分 source -->
              <div v-for="(basedSourceItem) in visibleSources(message)" :key="basedSourceItem.id">
                <div class="based_source" @click="openLink(basedSourceItem.source_data)">
                  {{ transformSourceName(basedSourceItem.source_data) }}
                  <img src="@/assets/img/sentosa/rightIcon.svg" class="rightIcon" />
                </div>
              </div>

              <!-- 显示查看更多按钮 -->
              <div v-if="message.sources.length > 3">
                <div @click="toggleShowAllSources(message)" class="view-more">
                  <img src="@/assets/img/sentosa/more_based_icon.svg" v-if="!message.showAllSources"
                    class="more_based_icon" />
                  <img src="@/assets/img/sentosa/more_based_up_icon.svg" v-else class="more_based_icon" />
                </div>
              </div>
            </div>

            <!-- Desktop transfer button -->
            <div class="transfer_agent_btn" @click="loadEmbeddedMessaging"
              v-if="message.status === 'success' && message.type === 'ai-message' && message.isTransfer && !isTransfer">
              <img src="@/assets/img/sentosa/transferUser_icon.svg" class="transfer_icon" />
              Transfer to live agent
            </div>

            <!--            <div class="text-with-lines" v-if="!isTransfer && isEndSession && index === messages.length -1">-->
            <!--              Chat rated successfully-->
            <!--            </div>-->
          </div>
        </div>

        <div class="conversation_footer">
          <div class="transfer_user_view">

            <div class="end_session_view" @click="handleEndSession" v-if="!isEndSession">
              <img src="@/assets/img/sentosa/start_new_chat_icon.svg" class="icon" />
              Rate & End Session (Your views matter!)
            </div>


            <div class="new-chat" @click="handleEndSession" v-if="isShowRestartBtn && !feedbackSubmitted ">
              <img src="@/assets/img/sentosa/start_new_chat_icon.svg" class="icon" />
              Rate Session(Your views matter!)
            </div>

          </div>

          <div class="input-wrapper">
            <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 5 }" resize="none" ref="inputRef2"
              v-model="inputValue" @input="handleInput" @compositionstart="handleCompositionStart"
              @compositionend="handleCompositionEnd" @keydown="handleKeydown" placeholder="Please enter..."
              :disabled="loading || isShowRestartBtn || isEndSession" />
            <template v-if="store.loading">
              <el-tooltip class="box-item" effect="dark" content="Stop generation" placement="bottom">
                <img src="@/assets/img/sentosa/stop.svg" class="send-logo" @click="clickStopChat" />
              </el-tooltip>
            </template>
            <template v-else>
              <img src="@/assets/img/sentosa/send_yellow.svg" v-if="isShowYellowSend" class="send-logo"
                @click="clickSendMsg(true)" />
              <img src="@/assets/img/sentosa/send.svg" v-else class="send-logo" @click="clickSendMsg(true)" />
            </template>


          </div>
          <div class="bottom-tips">I'm an AI assistant and still learning, so please verify any crucial information.
            For details on how we protect your data, please refer to our <a
              href="https://www.sentosa.com.sg/en/data-protection-policy/" target="_blank" class="policy-link">
              Data Protection Policy
            </a>.



          </div>
        </div>
      </div>
    </div>


  </div>

  <!-- //电脑端打分弹窗 -->
  <el-dialog v-model="dialogVisible " width="540px" :show-close="false"
    class="end-session-pc-dialog">
    <div class="voteView">
      <div class="content_view">
        Thank you for chatting with us.
      </div>
      <div class="desc_view">
        How was your experience?
      </div>
      <div class="operation_view">
        <div class="start_view">
          <div>
            <template v-for="index in 5" :key="index">
              <img :src="require(`@/assets/img/sentosa/${index <= starRating ? 'star_yellow' : 'no_star'}.svg`)"
                class="start_icon" @click="handleStarClick(index)" style="cursor: pointer;" />
            </template>
          </div>

          <div class="start_number_view">
            <span class="left_number">{{ starRating }}</span>
            <span class="right_number">/5 stars</span>
          </div>
        </div>


      </div>
      <div class="feedback_pc_view">
        <div class="feedback_desc">
          Share your feedback (Optional)
        </div>
        <el-input v-model="feedback" type="textarea" :maxlength="500" :show-word-limit="true"
          placeholder="What can we do to improve?" rows="2" :autosize="{ minRows: 2, maxRows: 5 }" />
        <div class="feedback_button_view">
          <!--加个取消 -->
          <div class="cancel_btn" @click="dialogVisible = false">Cancel</div>

          <!-- //如果是isShowRestartBtn  显示Submit -->
          <div :class="['submit_button', starRating === 0 ? 'no_select_btn' : '']" @click="handleFeedbackSubmit">
            {{ isShowRestartBtn ? 'Submit' : 'Submit' }}</div>
        </div>
      </div>
    </div>
  </el-dialog>

  <!-- //手机端打分弹窗 -->
  <el-dialog v-model="mobileDialogVisible " width="90%" :show-close="false" class="end-session-mobile-dialog">

    <div class="dialog-content">
      <div class="voteView_mobile">
        <div class="content_view">
          Thank you for chatting with us.
        </div>
        <div class="desc_view">
          How was your experience?
        </div>
        <div class="start_view">
          <div class="stars">
            <template v-for="index in 5" :key="index">
              <img :src="require(`@/assets/img/sentosa/${index <= starRating ? 'star_yellow' : 'no_star'}.svg`)"
                class="start_icon" @click="handleStarClick(index)" />
            </template>
          </div>
          <div class="start_number_view">
            <span class="left_number">{{ starRating }}</span>
            <span class="right_number">/5 stars</span>
          </div>
        </div>
        <div class="feedback_view">
          <div class="feedback_desc">Share your feedback (Optional)</div>
          <el-input v-model="feedback" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" :maxlength="500" :show-word-limit="true"
            placeholder="What can we do to improve?" rows="2" />
          <div class="feedback_button_view">


            <div :class="['submit_button', starRating === 0 ? 'no_select_btn' : '']" @click="handleFeedbackSubmit">
              {{ isShowRestartBtn ? 'Submit' : 'Submit' }}</div>
            <div class="cancel_button" @click="mobileDialogVisible = false">Cancel</div>

          </div>


        </div>
      </div>
    </div>
  </el-dialog>
  <!--  //电脑端-->
  <!--  <el-dialog v-model="dialogVisible" title="End Session" width="478px" :show-close="true" class="end-session-dialog">-->
  <!--    <div class="dialog-content">-->
  <!--      <img src="@/assets/img/sentosa/warning.svg" class="warning-icon" />-->
  <!--      <span>This will close the current session. Would you still like to end the chat?</span>-->
  <!--    </div>-->
  <!--    <template #footer>-->
  <!--      <div class="dialog-footer">-->
  <!--        <el-button class="cancel_btn" @click="dialogVisible = false">Cancel</el-button>-->
  <!--        <el-button type="primary" class="confirm_btn" @click="confirmEndSession">End Chat</el-button>-->
  <!--      </div>-->
  <!--    </template>-->
  <!--  </el-dialog>-->
  <!--  手机端-->

  <!--   <el-dialog v-model="mobileDialogVisible" width="90%" :show-close="false" class="end-session-mobile-dialog">-->
  <!--    <div class="dialog-content">-->
  <!--      <div class="title">Rate & End Session</div> &lt;!&ndash; 标题直接写在内容内 &ndash;&gt;-->
  <!--      <div class="title">(Your views matter)</div>-->
  <!--      <div class="desc">This will close the current session. Would you still like to end the chat?</div>-->
  <!--      <div class="button-group">-->
  <!--        <el-button @click="mobileDialogVisible = false" class="cancel_btn">Cancel</el-button>-->
  <!--        <el-button type="primary" @click="confirmEndSession" class="confirm_btn">End chat</el-button>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </el-dialog> -->


</template>

<style lang="less">
.end-session-pc-dialog {
  border-radius: 8px !important;
  padding: 0px !important;
  font-family: Montserrat;
  cursor: pointer;
  .el-textarea__inner{
    font-size: 14px!important;
    color:#033249!important;
  }


//输入框的滚动条隐藏
.el-textarea__inner::-webkit-scrollbar {
  display: none;
}

  .el-dialog__header {
    //margin: 0;
    //display: flex;
    //padding: 20px 30px;
    //align-items: center;
    //justify-content: space-between;
    //flex-shrink: 0;
    //border-radius: 8px 8px 0px 0px;
    //background: #FFF;
    //box-shadow: 0px -1px 0px 0px #DDE0E4 inset;
    display: none;


    .el-dialog__headerbtn {
      height: 20px !important;
      width: 20px !important;
      font-size: 20px !important;
      right: 30px !important;
      top: 20px !important;
    }

    .el-dialog__title {
      color: #1D252D;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-right: auto; // 让标题靠左
    }

  }

  .el-input__count{
    font-family: Montserrat;
    font-size: 14px!important;
  }

  .voteView {
    border-radius: 8px;
    background: #FFF;
    display: flex;
    padding: 24px 30px;
    flex-direction: column;

    .content_view {
      color: #033249;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .desc_view {
      margin-top: 4px;
      color: rgba(3, 50, 73, 0.80);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }


    .operation_view {
      font-family: Montserrat;
      height: 24px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 16px;

      .start_view {
        display: flex;
        font-family: Montserrat;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .start_icon {
          margin-right: 24px;
          width: 22px;
          height: 22px;
        }

        .start_number_view {
          font-family: Montserrat;
          .left_number {
            color: rgba(3, 50, 73, 0.60);
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }

          .right_number {
            font-family: Montserrat;
            color: rgba(3, 50, 73, 0.40);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }

        }
      }






    }

    .feedback_pc_view {
      margin-top: 20px;

      .feedback_title {
        color: #033249;

        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .feedback_desc {
        color: rgba(3, 50, 73, 0.80);
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
        /* 153.846% */
      }

      .feedback_button_view {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 16px;

        .no_select_btn {
          background: rgba(245, 146, 0, 0.50) !important;
          //鼠标禁用样式
          cursor: not-allowed !important;
        }

        .cancel_btn {
          margin-right: 10px;
          border-radius: 4px;
          background: #EDF2F7;
          border: none;
          display: flex;
          width: 87px;
          height: 36px;
          padding: 0 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #1D252D;
          text-align: center;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          /* 250% */

          &:hover {
            background: #E8ECF0;
          }

          &:active {
            background: #E5E7E9;
          }
        }

        .submit_button {
          display: flex;
          padding: 0 20px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 4px;
          background: #F59200;
          color: #FFF;
          text-align: center;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px;
          cursor: pointer;

          &:hover {
            background: #FFA733;
          }

          &:active {
            background: #E58900;
          }
        }
      }
    }

  }
}






.end-session-mobile-dialog {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  border-radius: 12px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  cursor: pointer;

  .el-textarea__inner::-webkit-scrollbar {
  display: none;
}


  .el-textarea__inner{
    font-size: 14px!important;
    color:#033249!important;
  }
  .el-dialog__header {
    padding: 0 !important;
  }

  .el-input__count{
    font-family: Montserrat;
    font-size: 14px!important;
  }

  // 根容器的样式
  .dialog-content {
    text-align: center;
    width: 100%;


    .title {
      color: #222;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 4px;
    }

    .desc {
      color: #222;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }


    .voteView_mobile {
      border-radius: 8px;
      background: #FFF;
      display: flex;
      flex-direction: column;
      text-align: left;


      .content_view {
        text-align: left;
        color: #033249;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .desc_view {
        text-align: left;
        color: rgba(3, 50, 73, 0.80);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-top: 2px;
        margin-bottom: 16px;
      }

      .start_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .stars {
          display: flex;
          align-items: center;
        }

        .start_icon {
          width: 22px;
          height: 22px;
          margin-right: 24px;
          cursor: pointer;
        }

        .start_number_view {
          .left_number {
            color: rgba(3, 50, 73, 0.60);
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
          }

          .right_number {
            color: rgba(3, 50, 73, 0.40);
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }




      .operation_view {
        cursor: pointer;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-around;
        background: #F5F7F9;

        .operation_content_view {
          background: #F5F7F9;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 0;
          flex: 1;

          &:hover {
            background: #F3F4F6;
          }
        }

        .line {
          width: 1px;
          height: 12px;
          flex-shrink: 0;
          background: #E7EAED;
        }

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .title {
          color: #66696C;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }


      }


      .feedback_view {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .feedback_title {
          color: #033249;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 4px;
        }

        .feedback_desc {
          color: #6E6E6E;
          font-family: Montserrat;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 4px;
        }

        .feedback_button_view {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 32px;

          .cancel_button,
          .submit_button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 100%;
            border-radius: 4px;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
          }

          .cancel_button {
            background: #EDF2F7;
            color: #1D252D;
            height: 40px;
            margin-top: 10px;


            &:hover {
              background: #E8ECF0;
            }

            &:active {
              background: #E5E7E9;
            }
          }

          .no_select_btn {
            background: rgba(245, 146, 0, 0.50) !important;
            cursor: not-allowed !important;
          }

          .submit_button {
            background: #F59200;
            color: #FFF;

            &:hover {
              background: #FFA733;
            }

            &:active {
              background: #E58900;
            }
          }
        }
      }

    }

    .button-group {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px; // 添加按钮之间的间距

      .cancel_btn,
      .confirm_btn {
        min-width: 106px;
        height: 40px;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        border: none;
        transition: all 0.8s ease;
      }

      .cancel_btn {
        background: #EDF2F7;
        color: #1D252D;

        &:hover {
          background: #E2E8F0;
        }

        &:active {
          background: #CBD5E0;
        }
      }

      .confirm_btn {
        background: #F59200;
        color: #FFF;

        &:hover {
          background: #FF9F0A;
        }

        &:active {
          background: #E58600;
        }
      }
    }
  }
}
</style>
<style scoped lang="less">
.sentosa-assistant-container {
  font-family: Montserrat;
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    font-family: Montserrat;
    z-index: 2;

    .new_chat_view {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 4px;
      margin-right: 64px;
      cursor: pointer;

      .new_chat_item_body {
        display: flex;
        align-items: center;
        padding: 10px;

        .new_chat_logo {
          width: 24px;
          height: 24px;
          margin-right: 12px;
          cursor: pointer;
        }

        .title {
          color: #033249;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          /* 116.667% */
          width: 95px;
        }

        &:hover {
          background-color: #F3F5F7;
        }
      }


    }


    .assistant-logo {
      width: 211px;
      height: 80px;
      margin-left: 50px;
      cursor: pointer;
    }
  }

  .assistant-content {
    display: flex;
    height: calc(100vh - 81px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Montserrat;
    margin-top: -100px;

    .assistant-title {
      color: #033249;
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      /* 150% */
    }

    .input-container {
      margin-top: 42px;
      width: 52vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .assistant-intro {
        display: flex;
        justify-content: center;

        img {
          width: 32px;
          height: 32px;
          margin-right: 13px;
          margin-top: 3px;
        }


        .assistant-intro_text {
          text-align: center;
          color: #033249;
          text-align: center;
          font-family: Montserrat;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px;
          /* 138.462% */

          span {
            font-weight: 600;
          }
        }


      }

      .input-wrapper {
        margin-top: 32px;
        display: flex;
        width: 100%;
        padding: 11px 20px;
        justify-content: center;
        border-radius: 30px;
        background: #F5F7F9;
        display: flex;
        align-items: center;
        cursor: pointer;


        .send_logo {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-left: 8px;
          cursor: pointer;
        }

        ::v-deep(.el-textarea__inner) {
          font-size: 16px;
          font-weight: 500;
          box-shadow: none !important;
          background-color: #F5F7F9;
        }
      }
    }

    .more-select-container {
      display: flex;
      margin-top: 50px;

      .more_item {
        cursor: pointer;
        width: 13.75vw;
        padding: 16px 12px;
        /* 保留上下16px，左右12px的padding */
        border-radius: 13px;
        background: #F7F9FB;
        margin-left: 12px;


        .title_view {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          /* 限制行数为5 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: calc(20px * 5 + 16px * 2);
          /* 行高5行 + 上下padding */

          color: #033249;
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          /* 每行高度 */
          box-sizing: border-box;
          /* 包括padding在高度计算内 */
        }

        &:hover {
          background: rgba(197, 208, 214, 0.30);
        }

      }


    }

    .footer {
      position: absolute;
      bottom: 16px;
      color: rgba(3, 50, 73, 0.50);
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      /* 133.333% */

      .policy-link {
        line-height: 20px;
        text-decoration: underline;
        color: rgba(3, 50, 73, 0.50);

        &:hover {
          color: rgba(3, 50, 73, 0.70);
        }
      }
    }


  }

  .conversation-content {
    display: flex;
    height: calc(100vh - 81px);
    flex-direction: column;

    .conversation-view {
      width: 52vw;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0 auto;
      height: calc(100vh - 81px);
      /* 留出上下空间 */

      .messages-container {
        padding-top: 20px;
        overflow-y: auto;
        /* 启用滚动 */
        padding-bottom: 20px;
        max-height: calc(100vh - 280px);
        /* 留出空间给输入框和边距 */
      }

      .messages-container::-webkit-scrollbar {
        display: none;
      }

      .message {
        display: flex;
        flex-direction: column;
        overflow-wrap: break-word;
        margin-bottom: 24px;
        font-family: Montserrat;

        .message-content {
          font-family: Montserrat;
          display: flex;
          align-items: flex-start;
          /* 左对齐 */
          cursor: pointer;


          .message_loading {
            display: flex;
            align-items: center;
            flex-direction: row;

            .loading_logo {
              width: 16px;
              height: 16px;
              animation: spin 1s linear infinite;
            }

            @keyframes spin {
              from {
                transform: rotate(0deg);
              }

              to {
                transform: rotate(360deg);
              }
            }

            .message-title {
              color: #99A0AD;
              font-family: Montserrat;
              margin-left: 5px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              /* 185.714% */
              letter-spacing: 0.28px;
            }
          }


          .assistant-logo {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            align-self: flex-start;
            /* 头像顶部对齐 */
          }

          .content {
            word-wrap: break-word;
            font-family: Montserrat;
            color: #033249;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px
          }


          .feedback-success {
            display: flex;
            align-items: center;
            margin-top: 3px;

            .success-icon {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }

            .success-text {
              color: #033249;
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
          }

          .voteView {
            border-radius: 8px;
            border: 1px solid #E6E7EA;
            background: #FFF;
            display: flex;
            width: 368px;
            padding: 16px;
            flex-direction: column;

            .content_view {
              color: #033249;
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }

            .desc_view {
              margin-top: 4px;
              color: rgba(3, 50, 73, 0.80);
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }


            .operation_view {
              height: 24px;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              flex: 1;
              margin-top: 16px;

              .start_view {
                display: flex;

                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .start_icon {
                  margin-right: 24px;
                  width: 22px;
                  height: 22px;
                }

                .start_number_view {
                  .left_number {
                    color: rgba(3, 50, 73, 0.60);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                  }

                  .right_number {
                    color: rgba(3, 50, 73, 0.40);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                  }

                }
              }






            }

            .feedback_pc_view {
              margin-top: 20px;

              .feedback_title {
                color: #033249;

                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 4px;
              }

              .feedback_desc {
                color: rgba(3, 50, 73, 0.80);
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 4px;
                /* 153.846% */
              }

              .feedback_button_view {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 16px;

                .no_select_btn {
                  background: rgba(245, 146, 0, 0.50) !important;
                  //鼠标禁用样式
                  cursor: not-allowed !important;
                }

                .submit_button {
                  display: flex;
                  padding: 0 20px;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 4px;
                  background: #F59200;
                  color: #FFF;
                  text-align: center;
                  font-family: Montserrat;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 36px;
                  cursor: pointer;

                  &:hover {
                    background: #FFA733;
                  }

                  &:active {
                    background: #E58900;
                  }
                }
              }
            }

          }




        }


        .vote-actions {
          display: flex;
          gap: 8px;
          margin-left: 40px;
          margin-top: 0;
          margin-bottom: 0;
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          transform: translateY(-10px);
          transition: all 0.3s ease;

          &.vote-actions-visible {
            margin-top: 8px;
            margin-bottom: 8px;
            max-height: 40px;
            opacity: 1;
            transform: translateY(0);
          }

          .vote-button {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background: #F3F4F6;
            }

            .vote-icon {
              width: 20px;
              height: 20px;
            }
          }
        }


        .based_source-view {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          font-family: Montserrat;

          .based_source {
            font-family: Montserrat;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            margin-left: 40px;
            border-radius: 6px;
            background: #F5F7F9;
            padding: 10px 12px;
            margin-top: 8px;
            color: #033249;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 114.286% */

            .rightIcon {
              width: 16px;
              height: 16px;
              margin-left: 8px;
            }
          }

          .view-more {

            cursor: pointer;
            margin-top: 16px;
            margin-left: 40px;
            display: flex;
            width: 32px;
            height: 32px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 30px;
            background: #F5F7F9;

            .more_based_icon {
              width: 16px;
              height: 16px;
            }
          }


        }

        .text-with-lines {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #99A0AD;
          margin-top: 24px;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px;
          /* 257.143% */
        }

        .text-with-lines::before,
        .text-with-lines::after {
          content: '';
          flex: 1;
          /* 让横线自动填充剩余空间 */
          height: 1px;
          background-color: #E7EAED;
          /* 横线颜色 */
          margin: 0 20px;
          /* 横线与文字之间的间距 */
        }

        /* 确保容器有足够的宽度，例如页面宽度 */

        .text-with-lines {
          width: 100%;
          /* 或者指定具体宽度，如 500px */
        }


        &.user-message {
          .message-content {
            justify-content: flex-end;
            /* 用户消息右对齐 */
            font-family: Montserrat;


            .body-view {
              border-radius: 12px 0px 12px 12px;
              background: #F3F5F7;


              .content {
                padding: 8px 16px;
              }
            }
          }
        }
      }


      .conversation_footer {

        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 16px;
        /* 输入框距离底部 50px */
        left: 50%;
        transform: translateX(-50%);
        /* 水平居中 */


        .transfer_user_view {
          width: 52vw;
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;

          .end_session_view {
            display: flex;
            padding: 5px 20px;
            justify-content: center;
            align-items: center;
            color: rgba(3, 50, 73, 0.60);
            margin-bottom: 8px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 0.28px;
            cursor: pointer;
            border-radius: 12px;
            background: #FEF6EB;
            color: #F59200;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
            /* 192.857% */

            .icon {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }

            &:hover {
              background: #FEF2E0;
            }
          }

          .new-chat {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 5px 16px;
            border-radius: 12px;
            background: #FEF6EB;
            text-align: center;
            color: rgba(3, 50, 73, 0.50);
            font-weight: 500;
            bottom: 8px;
            /* 输入框距离底部 50px */
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            /* 水平居中 */
            color: #F59200;
            font-family: Montserrat;
            font-size: 14px;
            line-height: 27px;
            /* 192.857% */

            .icon {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }

            &:hover {
              background: #FEF2E0;
            }
          }


        }


        .input-wrapper {

          width: 52vw;
          display: flex;
          padding: 11px 20px;
          justify-content: space-between;
          border-radius: 30px;
          background: #F5F7F9;
          align-items: center;
          cursor: pointer;

          .send_logo {
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-left: 8px;
            cursor: pointer;
          }

          ::v-deep(.el-textarea__inner) {
            font-size: 16px;
            font-weight: 500;
            box-shadow: none !important;
            background-color: #F5F7F9;
          }
        }

        .bottom-tips {
          text-align: center;
          color: rgba(3, 50, 73, 0.50);
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          margin-top: 16px;
          font-weight: 500;
          line-height: 16px;

          /* 133.333% */
          .policy-link {
            text-decoration: underline;
            font-weight: 500;
            line-height: 20px;
            color: rgba(3, 50, 73, 0.50);

            &:hover {
              color: rgba(3, 50, 73, 0.70);
            }
          }
        }


      }


    }
  }


}


//手机
.sentosa-buddy-container {
  background: #ffffff;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;

  .header {
    background-color: white;
    z-index: 10;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    //justify-content: space-between;


    .assistant-logo {
      width: 211px;
      cursor: pointer;
      margin-left: -10px;
    }

    .newChat_icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .assistant-icon {
      width: 32px;
      height: 32px;
    }
  }



  .scrollable-content {
    overflow-y: auto;
    max-height: calc(100vh - 260px);
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    //justify-content: center;

    &::-webkit-scrollbar {
      display: none;
    }

    .logo-row {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .assistant-logo {
        width: 32px;
        height: 32px;
      }
    }

    .greeting-title {
      color: #033249;
      text-align: center;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .assistant-intro {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 16px;

      .assistant-icon {
        width: 24px;
        height: 24px;
        margin-right: 11px;
      }

      .assistant-text {
        color: #033249;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .options {
      margin-top: 40px;
      margin-bottom: 16px;

      .option {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F7F9FB;
        color: #033249;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 12px;
        line-height: 20px;

        &:active {
          background: #e5e7eb;
        }
      }
    }
  }

  .fixed-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    background: #ffffff;
    padding: 20px 20px 17px 20px;
    box-shadow: 0px 1px 0px 0px #E7EAED inset;

    .input-wrapper {
      position: relative;
      margin-bottom: 8px;
      display: flex;
      padding: 11px 20px;
      justify-content: center;
      border-radius: 30px;
      background: #F5F7F9;
      align-items: center;
      //box-shadow: 0px 1px 0px 0px #E7EAED inset;
      cursor: pointer;

      .send-logo {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        cursor: pointer;
        cursor: pointer;
      }

      ::v-deep(.el-textarea__inner) {
        font-size: 16px;
        font-weight: 500;
        width: 90%;
        box-shadow: none !important;
        background-color: #F5F7F9;
      }
    }

    .disclaimer {
      color: rgba(3, 50, 73, 0.50);
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      .policy-link {
        text-decoration: underline;
        color: rgba(3, 50, 73, 0.50);

        &:hover {
          color: rgba(3, 50, 73, 0.70);
        }
      }

    }
  }
}

/* Desktop transfer button style */
.transfer_agent_btn {
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 110px;
  border: 1px solid #E7EAED;
  color: rgba(3, 50, 73, 0.60);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 185.714% */
  letter-spacing: 0.28px;
  cursor: pointer;
  margin-left: 40px;
  margin-top: 16px;
  width: fit-content;

  .transfer_icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  &:hover {
    border: 1px solid #F3F4F6;
    background: #F3F4F6;
  }
}

/* Mobile transfer button style */
.transfer_agent_btn_mobile {
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 110px;
  border: 1px solid #E7EAED;
  background: #FFF;
  color: rgba(3, 50, 73, 0.60);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 185.714% */
  letter-spacing: 0.28px;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 32px;
  width: fit-content;

  .transfer_icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  &:hover {
    border: 1px solid #F3F4F6;
    background: #F3F4F6;
  }

  &:active {
    border: 1px solid #F3F4F6;
    background: #E5E7E9;
  }
}

//手机-对话
.sentosa-dialog-container {

  .text-with-lines {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #99A0AD;
    margin-top: 24px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    /* 257.143% */
  }

  .text-with-lines::before,
  .text-with-lines::after {
    content: '';
    flex: 1;
    /* 让横线自动填充剩余空间 */
    height: 1px;
    background-color: #E7EAED;
    /* 横线颜色 */
    margin: 0 20px;
    /* 横线与文字之间的间距 */
  }


  padding: 0 20px 20px 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  font-family: Arial,
  sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .voteView_mobile {
    border-radius: 8px;
    border: 1px solid #E6E7EA;
    background: #FFF;
    width: 90%;
    display: flex;
    padding: 12px;
    flex-direction: column;


    .content_view {
      color: #033249;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .desc_view {
      color: rgba(3, 50, 73, 0.80);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-top: 2px;
      margin-bottom: 16px;
    }

    .start_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .stars {
        display: flex;
        align-items: center;
      }

      .start_icon {
        width: 22px;
        height: 22px;
        margin-right: 24px;
        cursor: pointer;
      }

      .start_number_view {
        .left_number {
          color: rgba(3, 50, 73, 0.60);
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }

        .right_number {
          color: rgba(3, 50, 73, 0.40);
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }




    .operation_view {
      cursor: pointer;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-around;
      background: #F5F7F9;

      .operation_content_view {
        background: #F5F7F9;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;
        flex: 1;

        &:hover {
          background: #F3F4F6;
        }
      }

      .line {
        width: 1px;
        height: 12px;
        flex-shrink: 0;
        background: #E7EAED;
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      .title {
        color: #66696C;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
      }


    }


    .feedback_view {
      margin-top: 20px;

      .feedback_title {
        color: #033249;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .feedback_desc {
        color: rgba(3, 50, 73, 0.80);
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .feedback_button_view {
        display: flex;
        justify-content: center;
        margin-top: 24px;
        height: 40px;

        .cancel_button,
        .submit_button {

          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
        }

        .cancel_button {
          background: #EDF2F7;
          color: #1D252D;
          width: 104px;
          height: 36px;
          margin-right: 10px;

          &:hover {
            background: #E8ECF0;
          }

          &:active {
            background: #E5E7E9;
          }
        }

        .no_select_btn {
          background: rgba(245, 146, 0, 0.50) !important;
          cursor: not-allowed !important;
        }

        .submit_button {
          padding: 0 20px;
          background: #F59200;
          color: #FFF;

          &:hover {
            background: #FFA733;
          }

          &:active {
            background: #E58900;
          }
        }
      }
    }

  }

  .feedback-success {
    display: flex;
    align-items: center;
    justify-content: center;

    .success-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .success-text {
      color: #033249;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }




  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 10;
    height: 64px;
    display: flex;
    align-items: center;
    //box-shadow: 0px -1px 0px 0px #E7EAED inset;
    padding: 0 20px;
    justify-content: space-between;


    .assistant-logo {
      width: 211px;
      cursor: pointer;
      margin-left: -10px;
    }

    .newChat_view {
      display: flex;
      align-items: center;

      .newChat_icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-right: 8px;
      }

      .title {
        width:71px;
        color: #6E6E6E;
        font-family: Montserrat;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 11px; /* 122.222% */


      }
    }




    .assistant-icon {
      width: 32px;
      height: 32px;
    }
  }

  .dialog-content {
    margin-top: 96px;
    margin-bottom: 160px;
    overflow-y: auto;
    max-height: calc(100vh - 260px);
    padding-bottom: 50px;

    .vote-actions {
      display: flex;
      margin-top: 0;
      margin-bottom: 12px;
      transition: all 0.3s ease;

      &.vote-actions-visible {
        margin-top: 8px;
        margin-bottom: 8px;
        max-height: 40px;
        opacity: 1;
        transform: translateY(0);
      }

      .vote-button {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background: #F3F4F6;
        }

        .vote-icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .message_loading {
      display: flex;
      align-items: center;
      flex-direction: row;

      .loading_logo {
        width: 16px;
        height: 16px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      .title {
        color: #99A0AD;
        font-family: Montserrat;
        margin-left: 5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 185.714% */
        letter-spacing: 0.28px;
      }
    }

    .message {

      // max-width: 90%;
      font-family: Montserrat;
      font-size: 16px;
      line-height: 20px;
      color: #033249;
      font-style: normal;
      font-weight: 500;
    }

    .user-message {
      display: inline-block; // 背景紧贴文本
      background: #f7f9fb;
      color: #033249;
      padding: 12px 16px;
      border-radius: 8px;
      margin-bottom: 24px;
      float: right; // 右对齐
      clear: both; // 清除浮动，确保独占一行
    }

    .bot-message {
      display: block; // 确保独占一行
      color: #033249;
      margin-right: auto;
      padding: 0; // 保持原样
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 12px;
      clear: both; // 清除浮动
    }

    .suggestions {
      margin-top: 16px;
      clear: both; // 确保建议框也在新行
      margin-bottom: 32px;

      .suggestion {
        display: inline-flex;
        padding: 9px 12px;
        border-radius: 6px;
        border: 1px solid #E7EAED;
        align-items: center;
        color: #033249;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 20px;
        cursor: pointer;

        .rightIcon {
          margin-left: 4px;
          vertical-align: middle;
          width: 16px;
          height: 16px;
        }


      }
    }

    &::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari, 和其他 Webkit 浏览器 */
    }

  }

  .fixed-bottom {
    position: fixed;
    bottom: 0;
    /* 从 20px 改为 0，贴底显示 */
    left: 0;
    /* 从 20px 改为 0，突破左侧 padding */
    right: 0;
    /* 从 20px 改为 0，突破右侧 padding */
    background: #ffffff;
    padding: 20px 20px 17px 20px;
    box-shadow: 0px 1px 0px 0px #E7EAED inset;

    .input-wrapper {
      position: relative;
      margin-bottom: 8px;
      display: flex;
      padding: 11px 20px;
      justify-content: center;
      border-radius: 30px;
      background: #f5f7f9;
      align-items: center;
      //box-shadow: 0px 1px 0px 0px #e7eaed inset;
      cursor: pointer;

      .send-logo {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      ::v-deep(.el-textarea__inner) {
        font-size: 16px;
        font-weight: 500;
        width: 90%;
        box-shadow: none !important;
        background-color: #f5f7f9;
      }
    }

    .disclaimer {
      margin: 0;
      color: rgba(3, 50, 73, 0.50);
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      .policy-link {
        text-decoration: underline;
        color: rgba(3, 50, 73, 0.50);

        &:hover {
          color: rgba(3, 50, 73, 0.70);
        }
      }
    }
  }

  .transfer_user_btn_mobile {
    position: fixed;
    bottom: 164px;
    /* 从 20px 改为 0，贴底显示 */
    right: 16px;
    /* 从 20px 改为 0，突破右侧 padding */

    .transfer_btn_view {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid #E7EAED;
      background: #FFF;
    }
  }

  .end_session_view_mobile {
    position: fixed;
    bottom: 155px;
    width: 318px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 20px;
    background: #FEF6EB;
    color: #F59200;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.28px;
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    &:hover {
      border: 1px solid #F3F4F6;
      background: #F3F4F6;
    }

    &:active {
      border: 1px solid #F3F4F6;
      background: #E5E7E9;
    }

  }

  .other_operation {
    position: fixed;
    bottom: 156px;
    /* 从 20px 改为 0，贴底显示 */
    left: 0;
    /* 从 20px 改为 0，突破左侧 padding */
    right: 0;
    /* 从 20px 改为 0，突破右侧 padding */
    background: white;
    justify-content: flex-end;
    align-items: center;

    .new-chat {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px 16px;
      border-radius: 12px;
      background: #FEF6EB;
      text-align: center;
      color: rgba(3, 50, 73, 0.50);
      font-weight: 500;
      bottom: 8px;
      /* 输入框距离底部 50px */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      /* 水平居中 */
      color: #F59200;
      width: 270px;
      font-family: Montserrat;
      font-size: 14px;
      line-height: 27px;
      /* 192.857% */

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      &:hover {
        background: #FEF2E0;
      }
    }

  }


}

@media (max-width: 640px) {
  .sentosa-dialog-container {
    width: 90%;

    .header {
      left: 0;
      right: 0;
      padding: 0 5%;
    }

    .fixed-bottom {}
  }
}



.voteView {
  .operation_view {
    .operation_content_view {
      &.active {
        color: #F59200;
      }
    }
  }


}


.voteView_mobile {
  .operation_view {
    .operation_content_view {
      &.active {
        color: #F59200;
      }
    }
  }
}

/* Desktop transfer button style */
.transfer_agent_btn {
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 110px;
  border: 1px solid #E7EAED;
  color: rgba(3, 50, 73, 0.60);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 185.714% */
  letter-spacing: 0.28px;
  cursor: pointer;
  margin-left: 40px;
  margin-top: 16px;
  width: fit-content;

  .transfer_icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  &:hover {
    border: 1px solid #F3F4F6;
    background: #F3F4F6;
  }
}

/* Mobile transfer button style */
.transfer_agent_btn_mobile {
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 110px;
  border: 1px solid #E7EAED;
  background: #FFF;
  color: rgba(3, 50, 73, 0.60);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 185.714% */
  letter-spacing: 0.28px;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 32px;
  width: fit-content;

  .transfer_icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  &:hover {
    border: 1px solid #F3F4F6;
    background: #F3F4F6;
  }

  &:active {
    border: 1px solid #F3F4F6;
    background: #E5E7E9;
  }
}
</style>


